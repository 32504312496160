/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'

// @ts-expect-error - no types available
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import addMinutes from 'date-fns/addMinutes'
import { useFlagsmith, useFlags } from 'flagsmith/react'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { FiCheckCircle } from 'react-icons/fi'
import { MdDateRange } from 'react-icons/md'
import { Navigate, useNavigate } from 'react-router-dom'

import {
  useCreateEventMutation,
  useGetAssessmentDatesQuery,
  useGetAvailableRecurringDatesMutation,
  useGetOnboardingCoachOptionsQuery,
  useMatchWithCoachMutation,
  useUpdateEventMutation,
  useUserReportMutation,
} from '../../app/services/api'

import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import Signup from '../auth/Signup'
import Datetime from './Datetime'
import { RecurringSessionItem } from './components/RecurringSessionItem'
import { useE2Etest } from '../../hooks/useE2Etest'
import { useAuth } from '../../hooks/useAuth'

const DEFAULT_SESSION_DURATION = 60

export const Book = ({
  // user,
  // coach,
  invitation = null,
  event = null,
  onEventUpdate = null,
}: any) => {
  const { t } = useLocale()
  const { user } = useAuth()
  if (!user) throw new Error('User is not defined')
  const flagsmith = useFlagsmith()
  const [anotherCoachSlot, setAnotherCoachSlot] = useState<any>(null)
  const { nextStep, reset, activeStep, setStep } = useSteps({
    initialStep: 0,
  })
  const { data: coachBios } = useGetOnboardingCoachOptionsQuery({
    companyId: user.company._id,
    language: user.language ?? 'en',
    departmentId: user?.department?._id,
    email: user.email,
  })
  // const [duration, setDuration] = useState<number>(30)
  const [isBookedNewSession, setIsBookedNewSession] = useState(false)
  const [isAskPopUpEnabled, setIsAskPopUpEnabled] = useState(false)
  const [maxAttempts, setMaxAttempts] = useState(3)
  const [attempts, setAttempts] = useState(0)
  const sessionDuration =
    // @ts-ignore
    user.company.sessionDuration ?? DEFAULT_SESSION_DURATION
  const [refetchAvailability, setRefetchAvailability] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [date, setDate] = useState<Date | null>(null)
  const [availableDatesCount, setAvailableDatesCount] = useState<number | null>(
    null
  )
  const [slotsCount, setSlotsCount] = useState(0)
  const [time, setTime] = useState<Date | null>(null)
  const [isRecurringBooking, setIsRecurringBooking] = useState<boolean>(false)
  const [recurringSessions, setRecurringSessions] = useState<any>([])
  const [loadingRecurringDates, setLoadingRecurringDates] =
    useState<boolean>(false)
  const [sessionCadence, setSessionCadence] = useState<string>('')
  const [futureEventsCount, setFutureEventsCount] = useState<number>(0)
  const [isCadenceChangeable, setIsCadenceChangeable] = useState<boolean>(false)
  const [remainingSessionCount, setRemainingSessionCount] = useState<number>(0)
  const [cadenceOptions, setCadenceOptions] = useState<string[]>([])

  const navigate = useNavigate()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isMidpointDueAlertOpen,
    onOpen: onMidpointDueAlertOpen,
    onClose: onMidpointDueAlertClose,
  } = useDisclosure()

  const mixpanel = useMixpanel()
  const { data: assessmentDates, isFetching: assessmentDatesFetching } =
    useGetAssessmentDatesQuery()
  const [report] = useUserReportMutation()

  const { recurring_booking } = useFlags(['recurring_booking'])

  const initialRef = useRef<HTMLDivElement>(null)
  const finalRef = useRef<HTMLDivElement>(null)
  const cancelRef = useRef<HTMLInputElement>(null)

  const isE2Etest = useE2Etest()

  const isPublic = invitation && !user
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const language = invitation ? invitation.language : user.language

  const [createEvent] = useCreateEventMutation()
  const [updateEvent] = useUpdateEventMutation()
  const [matchWithCoach] = useMatchWithCoachMutation()
  const [getAvailableRecurringDates] = useGetAvailableRecurringDatesMutation()

  const [inviteManager, setInviteManager] = useState(
    event?.managerInvited ?? false
  )

  useEffect(() => {
    const hidden = localStorage.getItem('hidePopupForBookingAttempts')
    console.warn('hidden:', hidden)

    setTimeout(() => {
      const isEnabled = flagsmith.hasFeature('popup_after_x_attempts')
      if (isEnabled && hidden !== 'true') {
        setIsAskPopUpEnabled(true)
      }

      const maxAttempts = flagsmith.getValue('popup_after_x_attempts')
      if (typeof maxAttempts === 'number') {
        console.log(maxAttempts, isEnabled, hidden)
        setMaxAttempts(maxAttempts)
      }
    }, 500)
  }, [flagsmith])

  const steps = useMemo(
    () => [
      // {
      //   label: t('choose_duration'),
      //   content: (
      //     <Duration
      //       options={DURATION_OPTIONS}
      //       setDuration={setDuration}
      //       onNext={nextStep}
      //     />
      //   ),
      // },
      {
        label: t('choose_date_time'),
        content: (
          <Datetime
            user={user}
            coach={user.coach}
            duration={sessionDuration}
            date={date}
            setDate={setDate}
            setAvailableDatesCount={setAvailableDatesCount}
            setSlotsCount={setSlotsCount}
            setTime={setTime}
            onNext={nextStep}
            timezone={timezone}
            language={language}
            inviteManager={inviteManager}
            setInviteManager={setInviteManager}
            refetchAvailability={refetchAvailability}
            setRefetchAvailability={setRefetchAvailability}
            attempts={attempts}
            setAttempts={setAttempts}
            isAskPopUpEnabled={isAskPopUpEnabled}
            setShouldShowAskPopUp={setIsAskPopUpEnabled}
            maxAttempts={maxAttempts}
            coachBios={coachBios}
            setAnotherCoachSlot={setAnotherCoachSlot}
            setFutureEventsCount={setFutureEventsCount}
            setRemainingSessionCount={setRemainingSessionCount}
          />
        ),
      },
    ],
    [
      t,
      attempts,
      coachBios,
      inviteManager,
      date,
      isAskPopUpEnabled,
      language,
      maxAttempts,
      nextStep,
      refetchAvailability,
      sessionDuration,
      timezone,
      user,
    ]
  )

  useEffect(() => {
    if (activeStep === 0) {
      mixpanel.track(event ? 'appointment_update' : 'appointment_create')
    } else if (activeStep === 2) {
      mixpanel.track(
        event ? 'appointment_update_date_time' : 'appointment_create_date_time'
      )
    }
  }, [activeStep, event, mixpanel])

  useEffect(() => {
    if (availableDatesCount) {
      mixpanel.track(
        event
          ? 'appointment_update_duration_select'
          : 'appointment_create_duration_select',
        {
          duration: sessionDuration,
          availableDatesCount,
        }
      )
    }
  }, [sessionDuration, availableDatesCount, mixpanel, event])

  useEffect(() => {
    if (date && slotsCount) {
      mixpanel.track(
        event
          ? 'appointment_update_date_select'
          : 'appointment_create_date_select',
        {
          selectedDate: moment(date).format('YYYY-MM-DD'),
          slotsCount,
        }
      )
    }
  }, [date, mixpanel, event, slotsCount])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (attempts === 3) {
      timeout = setTimeout(() => {
        if (!isBookedNewSession) {
          const type = 'NO_BOOKING_AFTER_3_ATTEMPTS'
          report({ type })
        }
      }, 5000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [attempts, isBookedNewSession, report])

  useEffect(() => {
    if (
      activeStep === steps.length &&
      recurring_booking.enabled &&
      futureEventsCount < 3 &&
      remainingSessionCount > 0
    ) {
      mixpanel.track('recurring_booking_feature_displayed')
    }
  }, [activeStep, steps, recurring_booking, futureEventsCount, mixpanel])

  const changeCoach = useCallback(
    async (coachId: string, coachEmail: string) => {
      try {
        await matchWithCoach({ coachId }).unwrap()
        mixpanel.track('coach_selection_switch_for_booking_success', {
          newcoach: coachEmail,
        })
      } catch (error: any) {
        mixpanel.track('coach_selection_switch_for_booking_error', {
          newcoach: coachEmail,
          error: error,
        })
        toast({
          status: 'error',
          title: t('error'),
          description: error.data?.message ?? t('there_was_an_error'),
          isClosable: true,
        })
      }
    },
    [t, mixpanel, toast, matchWithCoach]
  )

  const onConfirm = useCallback(async () => {
    try {
      const recurringSessionsList = isRecurringBooking
        ? recurringSessions
            ?.filter((rs: any) => rs.isAvailable)
            ?.map((s: any) => {
              return {
                start: s.sessionTime,
                end: addMinutes(
                  Date.parse(s.sessionTime as any),
                  sessionDuration
                ),
              }
            })
        : []

      const newEvent = {
        eventId: event?.id || event?._id,
        userId: user.coach._id,
        start: time as Date,
        end: addMinutes(Date.parse(time as any), sessionDuration),
        bookedByUser: true,
        inviteManager,
        recurringSessions: recurringSessionsList,
        isE2Etest,
      }
      setConfirming(true)

      const newEventResponse: any = event
        ? await updateEvent(newEvent).unwrap()
        : await createEvent(newEvent).unwrap()
      setConfirming(false)
      event
        ? onEventUpdate()
        : navigate(
            `/bookings${
              newEventResponse?.data?.[0]?._id
                ? `?eventId=${newEventResponse?.data?.[0]?._id}`
                : ''
            }`
          )
      mixpanel.track(
        event ? 'appointment_update_success' : 'appointment_create_success'
      )
      if (isRecurringBooking)
        mixpanel.track('recurring_sessions_booked', {
          recurringSessions: recurringSessionsList,
        })
      if (anotherCoachSlot) {
        changeCoach(anotherCoachSlot.id, anotherCoachSlot.email)
      }

      if (!event) {
        setIsBookedNewSession(true)
      }
    } catch (err: any) {
      const isEventAlredyBooked = err?.data?.code === 'EVENT_ALREADY_BOOKED'
      toast({
        status: 'error',
        title: t('error'),
        description:
          err.data?.message ??
          t(
            isEventAlredyBooked
              ? 'this_time_is_not_available'
              : 'there_was_an_error'
          ),
        isClosable: true,
      })
      if (isEventAlredyBooked) {
        setConfirming(false)
        reset()
        // just for refetching availability after reset
        setRefetchAvailability(true)
      }
      mixpanel.track(
        event ? 'appointment_update_fail' : 'appointment_create_fail'
      )
    }
  }, [
    t,
    anotherCoachSlot,
    changeCoach,
    time,
    sessionDuration,
    user.coach._id,
    event,
    createEvent,
    updateEvent,
    navigate,
    toast,
    onEventUpdate,
    mixpanel,
    inviteManager,
    reset,
    isE2Etest,
    isRecurringBooking,
    recurringSessions,
  ])

  const onModalClose = useCallback(async () => {
    await onClose()
    onConfirm()
  }, [onClose, onConfirm])

  const handleSetRecurring = async (event: any) => {
    setIsRecurringBooking(event.target.checked)
    if (event.target.checked && recurringSessions?.length === 0) {
      mixpanel.track('switched_recurring_booking')
      handleGetRecurringSessions()
    }
  }

  const handleChooseCadence = async (event: any) => {
    setSessionCadence(event.target.value)
    if (sessionCadence !== event.target.value) {
      mixpanel.track('selected_recurring_cadence_option')
      handleGetRecurringSessions(event.target.value)
    }
  }

  const handleGetRecurringSessions = async (weeks?: string) => {
    if (date) {
      setLoadingRecurringDates(true)
      const d = new Date(date)
      const utcDate = moment([
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        0,
        0,
        0,
      ]).utcOffset(0, true)

      const payload: any = {
        companyId: user.company._id,
        date: moment(utcDate).toISOString(),
        time: moment(time).toISOString(),
        coachId: user.coach._id,
        duration: sessionDuration,
        timezone: user?.timezone ?? timezone,
      }
      if (isCadenceChangeable) {
        payload.sessionCadence = weeks || sessionCadence
      }

      const { data }: any = await getAvailableRecurringDates(payload)

      if (!sessionCadence)
        setSessionCadence(data?.recurringCadenceConfig?.cadence)
      setIsCadenceChangeable(data?.recurringCadenceConfig?.isCadenceChangeable)
      setCadenceOptions(data?.recurringCadenceConfig?.cadenceOptions)
      setRecurringSessions(data?.recurringSessions)
      setLoadingRecurringDates(false)
    }
  }

  const handleRemoveRecurringSession = (index: number) => {
    const bookings = [...recurringSessions]
    bookings.splice(index, 1)
    setRecurringSessions(bookings)
  }

  const handleChangeRecurringSession = (newTime: any, sessionTime: any) => {
    const updatedBookings = recurringSessions?.map((booking: any) => {
      if (booking.sessionTime === sessionTime) {
        return {
          ...booking,
          isAvailable: true,
          sessionTime: newTime,
        }
      }
      return booking
    })

    setRecurringSessions(updatedBookings)
  }

  useEffect(() => {
    if (assessmentDates?.midpointAssessmentDue) {
      onMidpointDueAlertOpen()
    }
  }, [assessmentDates, onMidpointDueAlertOpen])

  if (assessmentDatesFetching) {
    return <Spinner />
  }

  if (assessmentDates?.initialAssessmentRequired) {
    return (
      <Navigate
        to={
          assessmentDates?.initialAssessmentNextQuestionIndex
            ? `/assessment?questionIndex=${assessmentDates?.initialAssessmentNextQuestionIndex}`
            : '/assessment'
        }
      />
    )
  }

  if (assessmentDates?.midpointAssessmentRequired) {
    return <Navigate to="/assessment/midpoint" />
  }

  return (
    <Flex flexDir="column" width="100%">
      <Steps
        onClickStep={(step: any) => setStep(step)}
        orientation="vertical"
        checkIcon={FiCheckCircle}
        activeStep={activeStep}
      >
        {steps.map(({ label, content }) => (
          <Step label={label} key={label}>
            {content}
          </Step>
        ))}
      </Steps>
      {activeStep === steps.length ? (
        <Center py={6}>
          <Box
            maxW={'400px'}
            w={'full'}
            bg="white"
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Box textAlign={'center'}>
              <Heading size="md" fontWeight={600} mb={2}>
                {t('duration_call_with_person', {
                  duration: t('duration_minutes', {
                    duration: sessionDuration,
                  }),
                  coachName:
                    anotherCoachSlot?.profile.name ?? user.coach.profile.name,
                })}
              </Heading>
              {inviteManager && (
                <Text fontSize="md" color="gray.900" mb={2}>
                  ({t('manager_invited')})
                </Text>
              )}
              <Stack direction={'row'} justify={'center'} spacing={0} mt={6}>
                <Stack spacing={0} align={'center'}>
                  <Flex alignItems="center">
                    <Text fontSize="23px">
                      <MdDateRange />
                    </Text>
                    <Text fontSize="md" pl={2} mr={5}>
                      {moment(date).format('MMM Do')}
                    </Text>
                    <Text fontSize="23px">
                      <AiOutlineClockCircle />
                    </Text>
                    <Text fontSize="md" pl={2}>
                      {moment(time)
                        .tz(user?.timezone ?? timezone)
                        .format('HH:mm ([GMT]Z)')}
                    </Text>
                  </Flex>
                </Stack>
              </Stack>
              {recurring_booking.enabled &&
              futureEventsCount < 3 &&
              remainingSessionCount > 0 ? (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Text>{t('set_recurring_booking')}</Text>
                  <Switch
                    size="md"
                    isChecked={isRecurringBooking}
                    onChange={handleSetRecurring}
                  />
                </Box>
              ) : null}

              {isRecurringBooking ? (
                <Box>
                  {isCadenceChangeable ? (
                    <Select
                      mb={2}
                      value={sessionCadence}
                      onChange={handleChooseCadence}
                    >
                      {cadenceOptions.map((c: any) => (
                        <option key={c} value={c}>
                          {t(c)}
                        </option>
                      ))}
                    </Select>
                  ) : recurringSessions?.length > 0 ? (
                    <Box textAlign="left">
                      <Text color="GrayText" mb={1}>
                        {t(sessionCadence)}
                      </Text>
                    </Box>
                  ) : null}
                  {loadingRecurringDates ? (
                    <Spinner mt={2} />
                  ) : (
                    recurringSessions?.map((s: any, i: number) => (
                      <RecurringSessionItem
                        session={s}
                        index={i}
                        timezone={user?.timezone ?? timezone}
                        handleChangeRecurringSession={
                          handleChangeRecurringSession
                        }
                        handleRemoveRecurringSession={
                          handleRemoveRecurringSession
                        }
                      />
                    ))
                  )}
                </Box>
              ) : null}
            </Box>
            <Stack mt={6} spacing={6} direction={['column', 'row']}>
              <Button
                bg={'red.400'}
                color={'white'}
                onClick={reset}
                w="full"
                _hover={{
                  bg: 'red.500',
                }}
                disabled={confirming}
              >
                {t('reset')}
              </Button>
              <Button
                bg={'blue.400'}
                color={'white'}
                onClick={isPublic ? onOpen : onConfirm}
                w="full"
                _hover={{
                  bg: 'blue.500',
                }}
                disabled={confirming}
              >
                {confirming ? <Spinner /> : t('confirm')}
              </Button>
            </Stack>
          </Box>
        </Center>
      ) : (
        <Flex width="100%" justify="flex-end"></Flex>
      )}
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Signup
              invitation={invitation}
              isModal={true}
              onModalClose={onModalClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isMidpointDueAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onMidpointDueAlertClose()
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('midpoint_review_due')}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t('midpoint_review_due_description')}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef as any}
                onClick={() => {
                  onMidpointDueAlertClose()
                }}
              >
                {t('later')}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  navigate('/assessment/midpoint')
                }}
                ml={3}
              >
                {t('complete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  )
}

export default Book
